<template>
  <div id="MapResults" class="Map">
    <LMap
      ref="map"
      :center="center"
      :maxZoom="7.75"
      :minZoom="4"
      :options="mapOptions"
      style="height: 80%;"
      :zoom.sync="zoom">
      <transition name="slide-fade-down">
        <div v-if="isRegionZoomed" class="map-reset" @click="reset" @keypress.enter="reset">
          <Icon name="arrow-back" />
          <span class="reset">Go back</span>
        </div>
      </transition>
      <div v-if="!isRegionZoomed">
        <LGeoJson
          v-for="region in regions"
          :key="region.name"
          :geojson="region.geojson"
          :layer="region.layer"
          :options="{
            smoothFactor: 1,
            style: () => ({
              weight: 1,
              color: 'grey',
              fillColor: 'transparent',
            }),
            onEachFeature: (feature, layer) => {
              layer.on({
                mouseover: () => {
                  layer.setStyle({
                    color: 'white',
                    weight: 2,
                  })
                },
                mouseout: () => {
                  layer.setStyle({
                    color: 'grey',
                    weight: 1,
                  })
                },
                click: () => {
                  showRegion(region, layer)
                },
              })
            },
          }" />
      </div>
      <div v-else>
        <LGeoJson
          :geojson="regionZoomed.geojson"
          :options="{
            smoothFactor: 3,
            style: () => ({
              weight: 1,
              color: 'grey',
              fillColor: 'transparent',
            }),
          }" />
      </div>
      <div v-if="!isRegionZoomed">
        <LMarkerCluster
          v-for="region in regions"
          :key="region.name"
          :options="{
            maxClusterRadius: 1000,
            iconCreateFunction,
            showCoverageOnHover: false,
            spiderfyOnMaxZoom: false,
            zoomToBoundsOnClick: false,
          }">
          <LMarker
            v-for="plant in region.plants"
            :key="plant.id"
            :icon="markerIcon"
            :latLng="[plant.latitude, plant.longitude]"
            @click="$emit('update:plantId', plant.id)" />
        </LMarkerCluster>
      </div>
      <div v-else>
        <LMarkerCluster
          :options="{
            maxClusterRadius: 40,
            iconCreateFunction: createIconCluster,
          }">
          <LMarker
            v-for="plant in regionZoomed.plants"
            :key="plant.id"
            :icon="createPlantIcon(plant)"
            :latLng="[plant.latitude, plant.longitude]"
            :name="plant.name" />
        </LMarkerCluster>
      </div>
    </LMap>
  </div>
</template>

<script>
import { divIcon, icon, latLng, latLngBounds, point } from 'leaflet'
import { LGeoJson } from 'vue2-leaflet'
import Map from 'views/mixins/Map'
import regions from './regions/regions.json'

const polandBounds = latLngBounds(
  latLng(48.675443, 24.479987),
  latLng(54.953175, 13.668961),
)
const center = latLng(52, 19)

export default {
  mixins: [Map],
  components: {
    LGeoJson,
  },
  data() {
    return {
      center,
      mapOptions: {
        attributionControl: false,
        dragging: false,
        maxBounds: polandBounds,
        zoomControl: false,
        zoomSnap: 0.25,
      },
      zoom: this.$store.getters.isDesktop ? 6 : 4.5,
      isRegionZoomed: false,
      regionZoomed: {},
    }
  },
  computed: {
    markerIcon() {
      return icon({
        iconAnchor: [13, 13],
        iconSize: [26, 26],
        iconUrl: require('../../../../assets/marker.png'),
      })
    },
    plantsPoland() {
      return this
        .query('country', { locale: this.locale, is_key_success: true })
        .flatMap(country => country.plants)
    },
    regions() {
      return regions.regions.map(region => ({
        plants: this.plantsPoland.filter(plant => plant.region === region.name),
        ...region,
      }))
    },
  },
  methods: {
    createPlantIcon(plant) {
      const additionalClass = plant.name.length > 12
        ? 'under'
        : ''

      return divIcon({
        className: 'plant-icon',
        iconAnchor: [4, 4],
        iconSize: [8, 8],
        html: `<div class="plant-label ${additionalClass}"><span>${plant.name}</span></div>`,
      })
    },
    createIconCluster(cluster) {
      const childPlantsNames = cluster
        .getAllChildMarkers()
        .map(({ _latlng: pos }) => this
          .plantsPoland
          .find(plant => plant.latitude === pos.lat && plant.longitude === pos.lng))
        .map(plant => plant.name)
        .sort((a, b) => a.length - b.length)
      const shortestPlantName = childPlantsNames[0].replace(/ [IVX]+/, '')
      const additionalClass = shortestPlantName.length > 12
        ? 'under'
        : ''

      return divIcon({
        iconSize: point(8, 8),
        html:
          `<div class="cluster-icon"><div class="plant-label ${additionalClass}">${shortestPlantName} (${cluster.getChildCount()})</div></div>`,
      })
    },
    showRegion(region, layer) {
      this.regionZoomed = region
      this.isRegionZoomed = true

      this.mapOptions.maxBounds = layer.getBounds()
      this.$refs.map.fitBounds(layer.getBounds())
      this.$emit('update:region', region)
    },
    reset() {
      this.isRegionZoomed = false
      this.mapOptions.maxBounds = polandBounds
      this.$refs.map.fitBounds(polandBounds)
      this.$emit('unzoomed')
    },
  },
  mounted() {
    this.reset()
  },
}
</script>

<style lang="scss">
$_plant-label-color: rgba($white, 0.8);

#MapResults {
  height: 150%;
  @include lg {
    height: 130%;
  }

  .leaflet-container {
    background-color: transparent;
  }

  .map-reset {
    color: $white;
    @include lg {
      top: 20%;
    }

    .reset {
      font-family: $font-body;
      font-size: $h5;
      font-weight: $semibold;
      line-height: 2.5rem;
    }
  }

  .leaflet-marker-icon {
    animation: none;
    pointer-events: none;
  }

  .cluster-icon,
  .plant-icon {
    background: $main;
    border-radius: 50%;
    height: 10px;
    position: relative;
    width: 10px;
  }

  .plant-label {
    color: $_plant-label-color;
    font-size: 0.75rem;
    position: absolute;
    transform: translate(-40%, -1.25rem);
    width: max-content;

    &.under {
      transform: translate(-40%, 1.25rem);
    }
  }
}
</style>
