
<script>
import 'leaflet/dist/leaflet.css'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css'
import L, { Icon, divIcon, point } from 'leaflet'
import { LMap, LMarker } from 'vue2-leaflet'
import GestureHandling from 'leaflet-gesture-handling'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import { getters } from 'views/utils'
delete Icon.Default.prototype._getIconUrl

export default {
  components: {
    LMap,
    LMarker,
    LMarkerCluster: Vue2LeafletMarkerCluster,
  },
  data() {
    return {
      doShowMap: true,
    }
  },

  computed: getters('isDesktop'),
  methods: {
    iconCreateFunction(cluster) {
      return divIcon({
        iconSize: point(26, 26),
        html:
          `<div class="plant-cluster"><div class="count">${cluster.getChildCount()}</div></div>`,
      })
    },
  },
  watch: {
    isDesktop: {
      handler() {
        if (this.isDesktop) {
          this.$refs.map.mapObject.gestureHandling.enable()
        } else {
          this.$refs.map.mapObject.gestureHandling.disable()
          this.$refs.map.mapObject.scrollWheelZoom.disable()
        }
      },
    },
    locale: {
      async handler() {
        this.doShowMap = false
        await this.$nextTick()
        this.doShowMap = true
      },
    },
  },
  mounted() {
    this.$refs.map.mapObject.touchZoom.disable()
    this.$refs.map.mapObject.doubleClickZoom.disable()
    this.$refs.map.mapObject.scrollWheelZoom.disable()
    this.$refs.map.mapObject.boxZoom.disable()
    this.$refs.map.mapObject.keyboard.disable()
    L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling)
  },
}
</script>

<style lang="scss">
$_icon-size: 26px;

.Map {
  bottom: 0;
  height: 99%;
  left: 0;
  min-height: 100px;
  position: absolute;
  right: 0;
  top: 0;
  @keyframes pulse {
    0% {}

    50% {
      box-shadow: 0 0 0 0.5rem rgba($info, 0.3);
    }
    100% {}
  }

  .map-reset {
    color: $white;
    cursor: pointer;
    display: flex;
    font-size: 1.5rem;
    padding: 1rem 1.5rem 1rem 0.5rem;
    position: absolute;
    z-index: 401;

    &:hover {
      .reset {
        text-decoration: underline;
      }
    }
  }

  .leaflet-div-icon {
    background: none;
    border: none;
  }

  .plant-cluster {
    position: relative;
    text-align: center;

    > .count {
      background: $info;
      border-radius: 50%;
      color: $white;
      display: inline-block;
      height: $_icon-size;
      line-height: ($_icon-size + 1px);
      position: relative;
      width: $_icon-size;
      z-index: 2;
    }

    .leaflet-marker-icon {
      animation-name: none;
      box-shadow: none;
    }
  }

  .leaflet-marker-icon {
    animation-delay: 1s;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-name: pulse;
    animation-timing-function: cubic-bezier(0.3, 0.85, 0.4, 1);
    border-radius: 50%;
    box-shadow: 0 0 0 0.125rem rgba($info, 0.3);
  }

  .leaflet-bar {
    border-radius: 0;

    a {
      height: 40px;
      line-height: 40px;
      width: 40px;

      &:hover {
        height: 40px;
        line-height: 40px;
        width: 40px;
      }

      &:first-child,
      &:last-child {
        border-radius: 0;
      }
    }
  }

  // @animation
  .slide-fade-down-enter-active,
  .slide-fade-down-leave-active {
    @include trans(1s);
  }

  .slide-fade-down-enter,
  .slide-fade-down-leave-to {
    opacity: 0;
    transform: translateY(-50px);
  }
}
</style>
