var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Map",attrs:{"id":"MapResults"}},[_c('LMap',{ref:"map",staticStyle:{"height":"80%"},attrs:{"center":_vm.center,"maxZoom":7.75,"minZoom":4,"options":_vm.mapOptions,"zoom":_vm.zoom},on:{"update:zoom":function($event){_vm.zoom=$event}}},[_c('transition',{attrs:{"name":"slide-fade-down"}},[(_vm.isRegionZoomed)?_c('div',{staticClass:"map-reset",on:{"click":_vm.reset,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.reset.apply(null, arguments)}}},[_c('Icon',{attrs:{"name":"arrow-back"}}),_c('span',{staticClass:"reset"},[_vm._v("Go back")])],1):_vm._e()]),(!_vm.isRegionZoomed)?_c('div',_vm._l((_vm.regions),function(region){return _c('LGeoJson',{key:region.name,attrs:{"geojson":region.geojson,"layer":region.layer,"options":{
          smoothFactor: 1,
          style: () => ({
            weight: 1,
            color: 'grey',
            fillColor: 'transparent',
          }),
          onEachFeature: (feature, layer) => {
            layer.on({
              mouseover: () => {
                layer.setStyle({
                  color: 'white',
                  weight: 2,
                })
              },
              mouseout: () => {
                layer.setStyle({
                  color: 'grey',
                  weight: 1,
                })
              },
              click: () => {
                _vm.showRegion(region, layer)
              },
            })
          },
        }}})}),1):_c('div',[_c('LGeoJson',{attrs:{"geojson":_vm.regionZoomed.geojson,"options":{
          smoothFactor: 3,
          style: () => ({
            weight: 1,
            color: 'grey',
            fillColor: 'transparent',
          }),
        }}})],1),(!_vm.isRegionZoomed)?_c('div',_vm._l((_vm.regions),function(region){return _c('LMarkerCluster',{key:region.name,attrs:{"options":{
          maxClusterRadius: 1000,
          iconCreateFunction: _vm.iconCreateFunction,
          showCoverageOnHover: false,
          spiderfyOnMaxZoom: false,
          zoomToBoundsOnClick: false,
        }}},_vm._l((region.plants),function(plant){return _c('LMarker',{key:plant.id,attrs:{"icon":_vm.markerIcon,"latLng":[plant.latitude, plant.longitude]},on:{"click":function($event){return _vm.$emit('update:plantId', plant.id)}}})}),1)}),1):_c('div',[_c('LMarkerCluster',{attrs:{"options":{
          maxClusterRadius: 40,
          iconCreateFunction: _vm.createIconCluster,
        }}},_vm._l((_vm.regionZoomed.plants),function(plant){return _c('LMarker',{key:plant.id,attrs:{"icon":_vm.createPlantIcon(plant),"latLng":[plant.latitude, plant.longitude],"name":plant.name}})}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }