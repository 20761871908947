var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Map",attrs:{"id":"MapPlantsLeaflet"}},[(_vm.doShowMap)?_c('LMap',{ref:"map",staticStyle:{"height":"80%"},attrs:{"attribution":_vm.attribution,"center":_vm.center,"gestureHandling":true,"maxZoom":16,"minZoom":3,"options":{
      zoomControl: true,
      scrollWheelZoom: false,
      gestureHandling: _vm.isDesktop,
      gestureHandlingText: _vm.gestureHandlingText,
      gestureHandlingOptions: {
        duration: 2000,
      },
    },"zoom":_vm.zoom},on:{"update:zoom":function($event){_vm.zoom=$event}}},[_c('LTileLayer',{attrs:{"attribution":_vm.attribution,"url":_vm.url}}),_vm._l((_vm.countries),function(country){return _c('LMarkerCluster',{key:country.code,attrs:{"options":{
        iconCreateFunction: _vm.iconCreateFunction,
        disableClusteringAtZoom: 14,
        maxClusterRadius: _vm.maxClusterRadius,
        showCoverageOnHover: false,
        spiderfyOnMaxZoom: false,
        zoomToBoundsOnClick: true,
      }},on:{"clusterclick":function($event){return _vm.onClusterClick(country)}}},_vm._l((_vm.plantsByCountryName[country.country]),function(plant){return _c('LMarker',{key:plant.id,attrs:{"icon":!country.hasActivePlants
          ? _vm.markerIcon
          : _vm.markerIconSingle,"latLng":[plant.latitude, plant.longitude]},on:{"click":function($event){country.hasActivePlants
          ? _vm.setActivePlantId(plant.id)
          : _vm.$emit(_vm.updateCountryId, country.id)}}})}),1)})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }