<template>
  <div id="MapPlantsLeaflet" class="Map">
    <LMap
      v-if="doShowMap"
      ref="map"
      :attribution="attribution"
      :center="center"
      :gestureHandling="true"
      :maxZoom="16"
      :minZoom="3"
      :options="{
        zoomControl: true,
        scrollWheelZoom: false,
        gestureHandling: isDesktop,
        gestureHandlingText,
        gestureHandlingOptions: {
          duration: 2000,
        },
      }"
      style="height: 80%;"
      :zoom.sync="zoom">
      <LTileLayer
        :attribution="attribution"
        :url="url" />
      <!--
      <LMarker
        v-for="office in offices"
        class="office"
        :icon="officeIcon"
        :key="`o-${office.id}`"
        :latLng="[office.latitude, office.longitude]"
        @click="$emit('update:officeId', office.id)" />
      -->
      <LMarkerCluster
        v-for="country in countries"
        :key="country.code"
        :options="{
          iconCreateFunction,
          disableClusteringAtZoom: 14,
          maxClusterRadius,
          showCoverageOnHover: false,
          spiderfyOnMaxZoom: false,
          zoomToBoundsOnClick: true,
        }"
        @clusterclick="onClusterClick(country)">
        <LMarker
          v-for="plant in plantsByCountryName[country.country]"
          :key="plant.id"
          :icon="!country.hasActivePlants
            ? markerIcon
            : markerIconSingle"
          :latLng="[plant.latitude, plant.longitude]"
          @click="country.hasActivePlants
            ? setActivePlantId(plant.id)
            : $emit(updateCountryId, country.id)" />
      </LMarkerCluster>
    </LMap>
  </div>
</template>

<script>
import { getters, groupByKey } from '../utils'
import { icon, latLng } from 'leaflet'
import { LTileLayer } from 'vue2-leaflet'
import Map from '../mixins/Map'

const center = latLng(50, 13)
// const updatePlantId = 'update:plantId'
// const updateCountryId = 'update:countryId'

export default {
  mixins: [Map],
  components: {
    LTileLayer,
  },
  props: {
    countryId: Number,
    officeId: Number,
    plantId: Number,
    localeOverride: {
      type: String,
      default: null,
    },
  },
  data() {
    const maxClusterRadius = (zoom) => {
      return this.getMaxClusterRadius(zoom)
    }

    return {
      updateCountryId: 'update:countryId',
      updatePlantId: 'update:plantId',

      plantFocused: {
        id: 0,
        zoom: 0,
      },
      countryFocused: {
        id: 0,
        zoom: 0,
      },
      hasActivePlantId: false,
      attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ',
      center,
      url: 'https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}',
      zoom: this.$store.getters.isDesktop ? 5 : 3,
      zoomSteps: [],
      maxClusterRadius,
    }
  },
  computed: {
    localeSelected() {
      return this.localeOverride || this.locale
    },
    gestureHandlingText() {
      return {
        touch: ' ',
        scroll: this.$t('zoomGestureDesktop'),
        scrollMac: this.$t('zoomGestureMac'),
      }
    },
    countries() {
      return this
        .query('country', { locale: this.localeSelected })
        .map(country => ({
          ...country,
          hasActivePlants: this.hasActivePlants(country),
        }))
    },
    countriesAllLocales() {
      return this.query('country')
    },
    markerIcon() {
      return icon({
        iconAnchor: [13, 13],
        iconSize: [26, 26],
        iconUrl: require('../../assets/marker.png'),
      })
    },
    markerIconSingle() {
      return icon({
        iconAnchor: [13, 13],
        iconSize: [26, 26],
        iconUrl: require('../../assets/marker-single.png'),
      })
    },
    // maxClusterRadius() {
    //   if (this.zoom > 8) return 50

    //   return 200
    // },
    plantsByCountryName() {
      const countriesByEnglishName = groupByKey(this.countriesAllLocales, 'country')
      return Object.fromEntries(Object
        .entries(countriesByEnglishName)
        .map(([countryName, countries]) => [
          countryName,
          countries
            .flatMap(country => country.plants)
            .filter(plant => !plant.locale || plant.locale === this.localeSelected),
        ]))
    },
    offices() {
      return this.query('office', {
        locale: this.localeSelected,
      })
    },
    zoomResetValue() {
      return this.isDesktop ? 5 : 3
    },
    ...getters('screenSizes'),

  },
  methods: {
    setActivePlantId(plantId) {
      this.plantFocused.id = plantId
      this.plantFocused.zoom = this.zoom
      this.$emit(this.updatePlantId, plantId)
    },
    getMaxClusterRadius(zoom) {
      const isLargeDisplay = this.screenSizes.includes('lg')
      if (zoom > 8) return isLargeDisplay ? 5 : 4
      if (zoom > 7) return isLargeDisplay ? 50 : 40
      if (zoom > 6) return isLargeDisplay ? 100 : 90
      if (zoom > 5 && !isLargeDisplay) return 40

      return 300
    },
    onClusterClick(country) {
      this.zoomSteps.push(this.zoom)
      this.$emit(this.updateCountryId, country.id)
      setTimeout(() => {
        if (!(country.id && (country.id !== this.countryFocused.id))) return
        this.countryFocused.id = country.id
        this.countryFocused.zoom = this.zoom
      }, 1000)
    },
    hasActivePlants(country) {
      return !(this.plantsByCountryName[country.country].length === 1 &&
        !this.plantsByCountryName[country.country][0].is_built)
    },
    zoomBack() {
      if (this.zoomSteps.length > 1) {
        this.zoom = this.zoomSteps.pop()
      } else {
        this.zoomSteps.pop()

        this.zoom = this.zoomResetValue
        this.center = center
        this.$refs.map.mapObject.setView(this.center)
        this.$emit('update:officeId', null)
        this.$emit(this.updateCountryId, null)
      }
      this.$emit(this.updatePlantId, null)
    },
  },
  watch: {
    zoom: {
      handler() {
        if (this.zoom < this.plantFocused.zoom) {
          this.$emit(this.updatePlantId, null)
          this.plantFocused.id = 0
          this.plantFocused.zoom = 0
        }
        if (this.zoom < this.countryFocused.zoom) {
          this.$emit(this.updateCountryId, null)
          this.countryFocused.id = 0
          this.countryFocused.zoom = 0
        }
      },
    },
  },
}
</script>

<style lang="scss">
#MapPlantsLeaflet {
  @include md {
    height: 125%;
  }

  .map-reset {
    background: $black-bis;
  }
}
</style>

<i18n>
{
  "de": {
    "zoomGestureDesktop": "Verwenden ctrl + scroll zum Zoomen",
    "zoomGestureMac": "Verwenden ⌘ + scroll zum Zoomen",
    "zoomOut": "Rauszoomen"
  },
  "en": {
    "zoomGestureDesktop": "Use ctrl + scroll to zoom",
    "zoomGestureMac": "Use ⌘ + scroll to zoom",
    "zoomOut": "Zoom out"
  },
  "it": {
    "zoomGestureDesktop": "",
    "zoomGestureMac": "",
    "zoomOut": ""
  },
  "lt": {
    "zoomGestureDesktop": "Norėdami priartinti, naudokite ctrl + scroll",
    "zoomGestureMac": "Norėdami priartinti, naudokite ⌘ + scroll",
    "zoomOut": "Tolinti"
  },
  "pl": {
    "zoomGestureDesktop": "Użyj ctrl + scroll, aby powiększyć",
    "zoomGestureMac": "Użyj ⌘ + scroll, aby powiększyć",
    "zoomOut": "Pomniejsz"
  }
}
</i18n>
